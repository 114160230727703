import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Footer from "../Footer";
import axios from "axios";
import ReactApexChart from "react-apexcharts";

const MyDashboard = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [myCeredit, setMyCredit] = useState(0);
  const [state, setState] = useState({
    series: [
      {
        name: "Credits",
        data: [],
      },
    ],
    options: {
      chart: {
        height: 250,
        type: "area",
        zoom: {
          enabled: false,
        },
        toolbar: {
          tools: {
            download: false,
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      title: {
        text: "Credits",
        align: "left",
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"],
          opacity: 0.5,
        },
      },
      xaxis: {
        categories: [],
      },
    },
  });
  const getDataFromlocalStorage = (key) => {
    try {
      const serializedData = localStorage.getItem(key);
      return serializedData ? JSON.parse(serializedData) : null;
    } catch (error) {
      console.error("Error getting data from localStorage:", error);
      return null;
    }
  };
  const baseUrl =
    "https://stagingsite.carloca.co.uk/apis/public/uploads/users/";
  const storedUserPreferences = getDataFromlocalStorage("userData");
  useEffect(() => {
    if (storedUserPreferences === null) {
      Swal.fire({
        title: "Unauthorized",
        text: "You need to be logged in to access this page",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#00889C",
        cancelButtonColor: "#d33",
        confirmButtonText: "Log In Now",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/login");
        } else {
          navigate("/");
        }
      });
      return;
    } else if (storedUserPreferences?.role !== "dealer") {
      Swal.fire({
        title: "Unauthorized Access",
        text: "Only dealer can access this!",
        icon: "warning",
        showCancelButton: false,
        confirmButtonColor: "#00889C",
        cancelButtonColor: "#d33",
        confirmButtonText: "Back To Home",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/");
        } else {
          navigate("/");
        }
      });
      return;
    }
  }, []);

  const formatNumber = (num) => {
    if (num >= 10000000) {
      return (num / 10000000).toFixed(1) + "M";
    } else if (num >= 100000) {
      return (num / 100000).toFixed(1) + "L";
    } else if (num >= 1000) {
      return (num / 1000).toFixed(1) + "K";
    } else {
      return num.toString();
    }
  };

  const getMyDashboard = async () => {
    try {
      const response = await axios.post(
        "https://stagingsite.carloca.co.uk/apis/public/api/get/dashboard",
        {
          user_id: storedUserPreferences?.id,
        }
      );
      if (response?.data?.status) {
        setData(response?.data?.data);
      }
    } catch (error) {
      console.log("An error occurred while fetching data.", error);
    }
  };

  const fetchData = async () => {
    try {
      const response = await axios.post(
        "https://stagingsite.carloca.co.uk/apis/public/api/get/credits",
        {
          user_id: storedUserPreferences?.id,
        }
      );
      setMyCredit(response?.data?.total_credit);
    } catch (error) {
      console.log("An error occurred while fetching data.", error);
    }
  };
  const fetchCredits = async () => {
    try {
      const response = await axios.post(
        "https://stagingsite.carloca.co.uk/apis/public/api/get/user/credits",
        {
          user_id: storedUserPreferences?.id,
        }
      );

      const myCredits = response?.data?.data;
      const categories = Object.keys(myCredits);
      const dataSeries = Object.values(myCredits);
      setState({
        series: [
          {
            name: "Credits",
            data: dataSeries,
          },
        ],
        options: {
          chart: {
            height: 250,
            type: "area",
            zoom: {
              enabled: false,
            },
            toolbar: {
              tools: {
                download: false,
              },
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: "smooth",
          },
          title: {
            text: "Credits",
            align: "left",
          },
          grid: {
            row: {
              colors: ["#f3f3f3", "transparent"],
              opacity: 0.5,
            },
          },
          xaxis: {
            categories: categories,
          },
        },
      });
    } catch (error) {
      console.log("An error occurred while fetching data.", error);
    }
  };
  useEffect(() => {
    getMyDashboard();
    fetchData();
    fetchCredits();
  }, []);

  return (
    <>
      <Navbar />
      <div className="new_page">
        <div
          className="approved_cars all_listings sml-card container mt-5 py-5"
          style={{ minHeight: "100vh" }}
        >
          <div className="d-flex align-items-center justify-content-md-between justify-content-center w-100 mb-3 px-0 flex-wrap">
            <h3 className="fs-5 fw-bold">Your Dashboard</h3>
            <div>
              <Link
                to="/dealer/all-cars"
                className="btn btn-primary me-2 border-0"
              >
                View All Cars
              </Link>
              <Link
                to="/dealer/list-my-cars"
                className="btn btn-primary border-0"
              >
                Add Car
              </Link>
            </div>
          </div>

          <div className="row">
            <div className="col-12 my-4">
              <h6 className="fw-bold">Selling</h6>
            </div>
            <div className="col-md-4 col-sm-6 col-lg-3 mb-3">
              <div className="card">
                <div className="card-body">
                  <div className="icon-card d-flex justify-content-center ">
                    <i className="fa fa-car fs-4"></i>
                  </div>
                  <div className="content-card mt-3 d-flex flex-column align-items-center">
                    <small className="text-muted mb-0">Total Cars</small>
                    <h5 className="fw-bold fs-4 ">{data?.total_cars}</h5>
                    <div className="d-flex mt-3 justify-content-between w-100">
                      <Link
                        to="/dealer/list-my-cars"
                        className="btn btn-primary border-0 btn-sm"
                      >
                        Add Car +
                      </Link>
                      <Link
                        to="/dealer/all-cars"
                        className="btn btn-primary border-0 btn-sm"
                      >
                        View All
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 col-lg-3 mb-3">
              <div className="card">
                <div className="card-body">
                  <div className="icon-card d-flex justify-content-center ">
                    <i className="fa fa-globe fs-4"></i>
                  </div>
                  <div className="content-card mt-3 d-flex flex-column align-items-center">
                    <small className="text-muted mb-0">Published Cars</small>
                    <h5 className="fw-bold fs-4 ">{data?.publish}</h5>
                    <div className="d-flex mt-3 justify-content-center w-100">
                      <Link
                        to="/dealer/all-cars"
                        className="btn btn-primary border-0 btn-sm"
                      >
                        View All
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 col-lg-3 mb-3">
              <div className="card">
                <div className="card-body">
                  <div className="icon-card d-flex justify-content-center ">
                    <i className="fa fa-globe fs-4"></i>
                  </div>
                  <div className="content-card mt-3 d-flex flex-column align-items-center">
                    <small className="text-muted mb-0">Unpublished Cars</small>
                    <h5 className="fw-bold fs-4 ">{data?.unpublish}</h5>
                    <div className="d-flex mt-3 justify-content-center w-100">
                      <Link
                        to="/dealer/all-cars"
                        className="btn btn-primary border-0 btn-sm"
                      >
                        View All
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 col-lg-3 mb-3">
              <div className="card">
                <div className="card-body">
                  <div className="icon-card d-flex justify-content-center ">
                    <i className="fa-regular fa-heart fs-4"></i>
                  </div>
                  <div className="content-card mt-3 d-flex flex-column align-items-center">
                    <small className="text-muted mb-0">Favourite Cars</small>
                    <h5 className="fw-bold fs-4 ">{data?.favourite}</h5>
                    <div className="d-flex mt-3 justify-content-center w-100">
                      <Link
                        to="/dealer/favourites-car"
                        className="btn btn-primary border-0 btn-sm"
                      >
                        View All
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-8 mb-3">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex flex-wrap">
                    <span className="fw-bold fs-6 me-3">Offers</span>
                    <div className="d-flex fw-bold align-items-center">
                      <i className="fa-solid fa-circle fs-6 me-2 text-primary"></i>
                      {data?.biddings && data?.biddings.length > 0
                        ? data?.biddings.length
                        : 0}
                    </div>
                  </div>
                  <div
                    className="table-responsive mt-3"
                    style={{ minHeight: "18rem" }}
                  >
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th className="fs-7 ">Car</th>
                          <th className="fs-7 ">Name</th>
                          <th className="fs-7 ">Price Offered</th>
                          <th className="fs-7 ">Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data?.biddings && data?.biddings.length > 0
                          ? data?.biddings.map((bid, index) => (
                              <tr key={index}>
                                <td className="fs-7 fw-light">
                                  <div style={{ minWidth: "12rem" }}>
                                    {bid?.name}
                                  </div>
                                </td>
                                <td className="fs-7 fw-light">
                                  <div style={{ minWidth: "7rem" }}>
                                    {bid?.bidder_name}
                                  </div>
                                </td>
                                <td className="fs-7 fw-light">
                                  <div style={{ minWidth: "5rem" }}>
                                    &pound;{formatNumber(bid?.bidder_price)}
                                  </div>
                                </td>
                                <td className="fs-7 fw-light">
                                  <div style={{ minWidth: "5rem" }}>
                                    {bid?.created_at}
                                  </div>
                                </td>
                              </tr>
                            ))
                          : ""}
                      </tbody>
                    </table>
                  </div>
                  <div className="d-flex justify-content-end">
                    <Link
                      to="/dealer/all-offers"
                      className="btn btn-sm btn-primary border-0"
                    >
                      View All
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-4">
              <div className="card shadow-sm">
                <h6 className="fw-bold m-3 fs-6">My Account</h6>
                <div className="card-body text-center">
                  <img
                    src={
                      storedUserPreferences?.profile !== null &&
                      storedUserPreferences?.profile !== undefined
                        ? `${baseUrl}${storedUserPreferences?.profile}`
                        : "img/user_default.png"
                    }
                    alt={
                      storedUserPreferences?.first_name !== null &&
                      storedUserPreferences?.first_name !== undefined
                        ? storedUserPreferences?.first_name
                        : ""
                    }
                    className="rounded-circle mb-3"
                    style={{ width: "4rem", height: "4rem" }}
                  />
                  <h5 className="card-title">
                    {storedUserPreferences?.first_name !== null &&
                    storedUserPreferences?.first_name !== undefined
                      ? storedUserPreferences?.first_name
                      : ""}
                  </h5>
                  <p className="text-muted">
                    {storedUserPreferences?.email !== null &&
                    storedUserPreferences?.email !== undefined
                      ? storedUserPreferences?.email
                      : ""}
                  </p>
                  <hr />
                  <div className="d-flex align-items-center flex-wrap mb-2">
                    <h6 className="fs-6 mb-0 fw-bold me-2">Active Plan:</h6>
                    <small className="text-muted">
                      {storedUserPreferences?.dealer_plan?.plan_code
                        .split("_")
                        .map(
                          (word) => word.charAt(0).toUpperCase() + word.slice(1)
                        )
                        .join(" ")}
                    </small>
                  </div>
                  <div className="d-flex align-items-center flex-wrap mb-2">
                    <h6 className="fs-6 mb-0 fw-bold me-2">Plan Expired On:</h6>
                    <small className="text-muted">
                      {storedUserPreferences?.dealer_plan?.expired_at}
                    </small>
                  </div>
                  <hr />
                  <div className="d-grid gap-2">
                    <div className="d-flex flex-wrap justify-content-between">
                      <Link
                        to="/dealer/edit-profile"
                        className="btn border-0 mb-2 btn-primary btn-sm"
                      >
                        Edit Profile
                      </Link>
                      <Link
                        to="/dealer/edit-profile"
                        className="btn border-0 mb-2 btn-primary btn-sm"
                      >
                        Manage Account
                      </Link>
                    </div>
                    <div className="d-flex flex-wrap justify-content-between">
                      <Link
                        to="/dealer/plans"
                        className="btn border-0 mb-2 btn-primary btn-sm"
                      >
                        Upgrade/Downgrade
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 my-4">
              <h6 className="fw-bold">Buying</h6>
            </div>
            <div className="col-md-4 col-sm-6 col-lg-3 mb-3">
              <div className="card">
                <div className="card-body">
                  <div className="icon-card d-flex justify-content-center ">
                    <i className="fa fa-lock fs-4"></i>
                  </div>
                  <div className="content-card mt-3 d-flex flex-column align-items-center">
                    <small className="text-muted mb-0">Unlocked Cars</small>
                    <h5 className="fw-bold fs-4 ">{data?.total_biddings}</h5>
                    <div className="d-flex mt-3 justify-content-center w-100">
                      <Link
                        to="/dealer/unlocked-cars"
                        className="btn btn-primary border-0 btn-sm"
                      >
                        View All
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 col-lg-3 mb-3">
              <div className="card">
                <div className="card-body">
                  <div className="icon-card d-flex justify-content-center ">
                    <i className="fa fa-circle-info fs-4"></i>
                  </div>
                  <div className="content-card mt-3 d-flex flex-column align-items-center">
                    <small className="text-muted mb-0">Pending Offers</small>
                    <h5 className="fw-bold fs-4 ">{data?.pending}</h5>
                    <div className="d-flex mt-3 justify-content-center w-100">
                      <Link
                        to="/dealer/pending-unlocks"
                        className="btn btn-primary border-0 btn-sm"
                      >
                        View All
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 col-lg-3 mb-3">
              <div className="card">
                <div className="card-body">
                  <div className="icon-card d-flex justify-content-center ">
                    <i className="fa-solid fa-ban fs-4"></i>
                  </div>
                  <div className="content-card mt-3 d-flex flex-column align-items-center">
                    <small className="text-muted mb-0">Denied Offers</small>
                    <h5 className="fw-bold fs-4 ">{data?.reject}</h5>
                    <div className="d-flex mt-3 justify-content-center w-100">
                      <Link
                        to="/dealer/decline-offers"
                        className="btn btn-primary border-0 btn-sm"
                      >
                        View All
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 col-lg-3 mb-3">
              <div className="card">
                <div className="card-body">
                  <div className="icon-card d-flex justify-content-center ">
                    <i className="fa-regular fa-heart fs-4"></i>
                  </div>
                  <div className="content-card mt-3 d-flex flex-column align-items-center">
                    <small className="text-muted mb-0">Accepted Offers</small>
                    <h5 className="fw-bold fs-4 ">{data?.accept}</h5>
                    <div className="d-flex mt-3 justify-content-center w-100">
                      <Link
                        to="/dealer/accept-offers"
                        className="btn btn-primary border-0 btn-sm"
                      >
                        View All
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12 col-md-12">
              <div className="card">
                <div className="card-body ">
                  <h6 className="fw-bold fs-6">My Credits</h6>
                  <ReactApexChart
                    options={state.options}
                    series={state.series}
                    type="area"
                    height={250}
                  />
                </div>
                <div className="w-100 d-flex justify-content-between align-items-center p-3">
                  <div className="d-flex align-items-start  flex-column mt-3">
                    <span className="fw-bold">Credit Balance</span>
                    <h2 className="fs-5 fw-bold mb-0"> {myCeredit} </h2>
                  </div>
                  <Link
                    to="/buyplan"
                    className="btn btn-primary border-0 btn-sm"
                  >
                    Get Credits
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};

export default MyDashboard;
