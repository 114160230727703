import React from "react";
import Home from "./Home";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle";
import "./index.css";
import { HashRouter as Router, Route, Routes, Outlet } from "react-router-dom";
import Favourites from "./Favourites";
import Login from "./pages/Login";
import ForgetPassword from "./pages/ForgetPassword";
import SignIn from "./pages/SignIn";
import Register from "./pages/Register";
import ConfirmReg from "./pages/ConfirmReg";
import ChangePass from "./pages/ChangePass";
import Cardetail from "./components/Cardetail";
import MyListing from "./dashPages/MyListing";
import ListDetails from "./dashPages/ListDetails";
import ListedPreview from "./dashPages/ListedPreview";
import UnlockReq from "./dashPages/UnlockReq";
import PendingUnlocks from "./dashPages/PendingUnlocks";
import UnlockedCars from "./dashPages/UnlockedCars";
import MyCredits from "./dashPages/MyCredits";
import ProfileSetting from "./dashPages/ProfileSetting";
import DeleteAccount from "./dashPages/DeleteAccount";
import ListMyCar from "./components/ListMyCar";
import BuyPlan from "./dashPages/BuyPlan";
import PaymentDetails from "./dashPages/PaymentDetails";
import Logout from "./pages/Logout";
import PurchaseCredit from "./dashPages/PurchaseCredit";
import AppRequests from "./dashPages/AppUnlockReq";
import Cardetail1 from "./components/Cardetail1";
import FilterCarData from "./components/FilterCarData";
import RejectedRequest from "./dashPages/RejectedRequest";
import ScrollToTop from "./ScrollToTop";
import ThankYou from "./pages/ThankYou";
import CreditSuccess from "./pages/CreditSuccess";
import DealerRegistration from "./pages/DealerRegistration";
import EditCar from "./dashPages/EditCar";
import TermsConditions from "./pages/TermsConditions";
import RejectedRequestBuyer from "./dashPages/RejectedRequestBuyer";
import AcceptOffer from "./pages/AcceptOffer";
import RejectOffer from "./pages/RejectOffer";
import MyInventory from "./dealer/MyInventory";
import MyUnlockRequest from "./dealer/MyUnlockRequest";
import MyApprovedUnlockRequest from "./dealer/MyApprovedUnlockRequest";
import MyPendingUnlocks from "./dealer/MyPendingUnlocks";
import MyUnlockedCars from "./dealer/MyUnlockedCars";
import ListDealerCars from "./dealer/ListDealerCars";
import ViewMyCarlist from "./dealer/ViewMyCarlist";
import EditMySavedCar from "./dealer/EditMySavedCar";
import ViewSavedCarDetail from "./dealer/ViewSavedCarDetail";
import Dashboard from "./dealer/MyDashboard";
import AllMyCars from "./dealer/AllMyCars";
import Upgrade from "./dealer/Upgrade";
import CheckoutUpgrade from "./dealer/CheckoutUpgrade";
import SuccessfullyUpgrade from "./dealer/SuccessfullyUpgrade";
import AllMyOffers from "./dealer/AllMyOffers";
import DealerStore from "./dealer/DealerStore";
import EditDealerProfile from "./dealer/EditDealerProfile";

const App = () => {
  return (
    <>
      {/* <Home/> */}
      <Router basename="/">
        <Routes>
          <Route
            path="/"
            element={
              <>
                <ScrollToTop />
                <Layout>
                  <Outlet />
                </Layout>
              </>
            }
          >
            <Route exact path="/" element={<Home />} />
            <Route exact path="/home" element={<Home />} />
            <Route exact path="/FilterCarData" element={<FilterCarData />} />
            <Route exact path="/listmycar" element={<ListMyCar />} />
            <Route exact path="/fav" element={<Favourites />} />
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/forgetpassword" element={<ForgetPassword />} />
            <Route exact path="/signin" element={<SignIn />} />
            <Route exact path="/register" element={<Register />} />
            <Route exact path="/dealer-register" element={<DealerRegistration />} />
            <Route exact path="/confreg" element={<ConfirmReg />} />
            <Route exact path="/changepass" element={<ChangePass />} />
            <Route exact path="/logout" element={<Logout />} />
            <Route exact path="/cardetails" element={<Cardetail />} />
            <Route exact path="/cardetailsNew" element={<Cardetail1 />} />
            <Route exact path="/mylisting" element={<MyListing />} />
            <Route exact path="/listdetails" element={<ListDetails />} />
            <Route exact path="/listpreview" element={<ListedPreview />} />
            <Route exact path="/edit/mycar" element={<EditCar />} />
            <Route exact path="/offers" element={<UnlockReq />} />
            <Route exact path="/apprequests" element={<AppRequests />} />
            <Route exact path="/pendingunlocks" element={<PendingUnlocks />} />
            <Route exact path="/unlockedcars" element={<UnlockedCars />} />
            <Route exact path="/mycredits" element={<MyCredits />} />
            <Route exact path="/profilesetting" element={<ProfileSetting />} />
            <Route exact path="/deleteAccount" element={<DeleteAccount />} />
            <Route exact path="/buyplan" element={<BuyPlan />} />
            <Route exact path="/paymentdetails" element={<PaymentDetails />} />
            <Route exact path="/purchasecredit" element={<PurchaseCredit />} />
            <Route exact path="/credits-success" element={<CreditSuccess />} />
            <Route exact path="/term-and-condition" element={<TermsConditions />} />
            <Route exact path="/accept-offer" element={<AcceptOffer />} />
            <Route exact path="/decline-offer" element={<RejectOffer />} />

            {/* routes for dealer */}
            <Route exact path="/dealer/my-inventory" element={<MyInventory />} />
            <Route exact path="/dealer/unlock-requests" element={<MyUnlockRequest />} />
            <Route exact path="/dealer/approved-unlock-request" element={<MyApprovedUnlockRequest />} />
            <Route exact path="/dealer/pending-unlocks" element={<MyPendingUnlocks />} />
            <Route exact path="/dealer/unlocked-cars" element={<MyUnlockedCars />} />
            <Route exact path="/dealer/list-my-cars" element={<ListDealerCars />} />
            <Route exact path="/dealer/view-saved-cars" element={<ViewMyCarlist />} />
            <Route exact path="/dealer/edit-saved/publish/cars" element={<EditMySavedCar/>} />
            <Route exact path="/dealer/saved/car/detail" element={<ViewSavedCarDetail />} />
            <Route exact path="/dealer/dashboard" element={<Dashboard />} />
            <Route exact path="/dealer/favourites-car" element={<Favourites />} />
            <Route exact path="/dealer/decline-offers" element={<RejectOffer />} />
            <Route exact path="/dealer/accept-offers" element={<AcceptOffer />} />
            <Route exact path="/dealer/edit-profile" element={<ProfileSetting />} />
            <Route exact path="/dealer/all-cars" element={<AllMyCars />} />
            <Route exact path="/dealer/all-offers" element={<AllMyOffers />} />
            <Route exact path="/dealer/plans" element={<Upgrade />} />
            <Route exact path="/dealer/checkout" element={<CheckoutUpgrade />} />
            <Route exact path="/dealer/success" element={<SuccessfullyUpgrade />} />
            <Route exact path="/dealer/store" element={<DealerStore />} />
            <Route exact path="/edit/dealer/profile" element={<EditDealerProfile />} />
            <Route
              exact
              path="/rejectedrequests"
              element={<RejectedRequest />}
            />
            <Route
              exact
              path="/declined-request"
              element={<RejectedRequestBuyer />}
            />
            <Route exact path="/thankyou" element={<ThankYou />} />
          </Route>
        </Routes>
      </Router>
    </>
  );
};
function Layout({ children }) {
  return <div>{children}</div>;
}

export default App;
