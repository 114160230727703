import React from "react";
import { Link, NavLink } from "react-router-dom";

const Navbar = () => {
  const baseUrl =
    "https://stagingsite.carloca.co.uk/apis/public/uploads/users/";

  const getDataFromlocalStorage = (key) => {
    try {
      const serializedData = localStorage.getItem(key);
      return serializedData ? JSON.parse(serializedData) : null;
    } catch (error) {
      console.error("Error getting data from localStorage:", error);
      return null;
    }
  };
  const storedUserPreferences = getDataFromlocalStorage("userData");
  return (
    <>
      <nav className="navbar bg-white fixed-top">
        <div className="container">
          <Link to="/" className="navbar-brand d-flex align-items-center me-4">
            <img src="img/logo.png" style={{ width: "50px" }} alt="" />
            <h3 className="m-0  ms-1">Carloca</h3>
          </Link>
          {storedUserPreferences !== null && (
            <div className="d-flex">
              <div className="nav-item dropdown ms-auto me-4">
                <Link
                  className="nav-link dropdown-toggle d-flex flex-column"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <div
                    style={{
                      height: "2.3rem",
                      width: "2.3rem",
                      border: "2px solid #ddd",
                      borderRadius: "50%",
                    }}
                  >
                    <img
                      src={
                        storedUserPreferences?.profile !== null &&
                        storedUserPreferences?.profile !== undefined
                          ? `${baseUrl}${storedUserPreferences?.profile}`
                          : "img/user_default.png"
                      }
                      style={{
                        objectFit: "fill",
                        objectPosition: "center",
                        borderRadius: "50%",
                      }}
                      className="h-100 w-100"
                      alt="user profile"
                    />
                  </div>
                  <h6
                    className="fw-semibold mb-0 pb-0"
                    style={{ fontSize: "12px" }}
                  >
                    {storedUserPreferences?.first_name}
                  </h6>
                </Link>
                <ul className="dropdown-menu rounded-0">
                  <li>
                    {storedUserPreferences?.role === "dealer" ? (
                      <Link to="/dealer/store" state={{'owner_id':storedUserPreferences?.id}} className="dropdown-item">
                        My Profile
                      </Link>
                    ) : (
                      <Link to="/profilesetting" className="dropdown-item">
                        My Profile
                      </Link>
                    )}
                  </li>

                  <li>
                    <Link to="/logout" className="dropdown-item">
                      Logout
                    </Link>
                  </li>
                </ul>
              </div>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasNavbar"
                aria-controls="offcanvasNavbar"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
            </div>
          )}
          {storedUserPreferences == null && (
            <Link to="/login" className="nav-link fw-bolder text-primary">
              Login <i className="fa-solid fa-lock ml-2"></i>
            </Link>
          )}

          <div
            className="offcanvas offcanvas-end"
            tabIndex="-1"
            id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbarLabel"
          >
            <div className="offcanvas-header">
              <h5 className="offcanvas-title blue" id="offcanvasNavbarLabel">
                Carloca
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>
            <div className="offcanvas-body ">
              <div className="d-flex flex-column justify-content-center align-items-center">
                <img
                  className="profile_img"
                  src={
                    storedUserPreferences?.profile !== null &&
                    storedUserPreferences?.profile !== undefined
                      ? `${baseUrl}${storedUserPreferences?.profile}`
                      : "img/user_default.png"
                  }
                  alt=""
                />
                <h6 className="fw-bold text-primary fs-6">
                  {storedUserPreferences?.first_name}
                </h6>
               {storedUserPreferences?.role==="dealer" ? (
                <div className="d-flex flex-wrap justify-content-around  w-100">
                  <Link
                    to="/edit/dealer/profile"
                    className="btn border-0  btn-primary"
                  >
                    Edit Profile
                  </Link>
                  <Link
                    to="/dealer/store"
                    state={{ owner_id: storedUserPreferences?.id }}
                    className="btn border-0 btn-primary"
                  >
                    View Profile
                  </Link>
                </div>
               ):(
                <Link
                    to="/profilesetting"
                    className="btn border-0  btn-primary"
                  >
                    Edit Profile
                  </Link>
               )}
              </div>
              {storedUserPreferences?.role === "user" ? (
                <ul className="navbar-nav justify-content-end flex-grow-1  mt-3">
                  <h6
                    className="text-center mb-0 text-primary pb-0 fw-bold"
                    style={{ fontSize: "14px" }}
                  >
                    Selling
                  </h6>
                  <div className="styled-hr mt-2"></div>
                  <li className="nav-item ">
                    <NavLink to="/mylisting" className="nav-link ">
                      My Listing
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/offers" className="nav-link">
                      Offers
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/apprequests" className="nav-link">
                      Approved Offers
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/rejectedrequests" className="nav-link">
                      Rejected Offers
                    </NavLink>
                  </li>
                  <h6
                    className="text-center text-primary mb-0 pb-0 fw-bold"
                    style={{ fontSize: "14px" }}
                  >
                    Buying
                  </h6>
                  <div className="styled-hr mt-2"></div>
                  <li className="nav-item">
                    <NavLink to="/pendingunlocks" className="nav-link">
                      Pending Unlocks
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/unlockedcars" className="nav-link">
                      Unlocked Cars
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/declined-request" className="nav-link">
                      Declined Offers
                    </NavLink>
                  </li>
                  <div className="styled-hr"></div>

                  <li className="nav-item">
                    <NavLink to="/mycredits" className="nav-link">
                      My Credits
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/fav" className="nav-link">
                      Favourites
                    </NavLink>
                  </li>
                </ul>
              ) : (
                <ul className="navbar-nav justify-content-end flex-grow-1  mt-3">
                  <div className="styled-hr mt-2"></div>
                  <li className="nav-item ">
                    <NavLink
                      to="/dealer/dashboard"
                      className="nav-link px-4 d-flex align-items-center text-start"
                    >
                      My Dashboard
                    </NavLink>
                  </li>
                  <li className="nav-item ">
                    <NavLink
                      to="/dealer/my-inventory"
                      className="nav-link px-4 d-flex align-items-center text-start"
                    >
                      My Inventory
                    </NavLink>
                  </li>
                  <li className="nav-item ">
                    <NavLink
                      to="/dealer/unlock-requests"
                      className="nav-link px-4 d-flex align-items-center text-start"
                    >
                      Unlock Requests
                    </NavLink>
                  </li>
                  <li className="nav-item ">
                    <NavLink
                      to="/dealer/approved-unlock-request"
                      className="nav-link px-4 d-flex align-items-center text-start"
                    >
                      Approved Unlock Requests
                    </NavLink>
                  </li>
                  <li className="nav-item ">
                    <NavLink
                      to="/dealer/pending-unlocks"
                      className="nav-link px-4 d-flex align-items-center text-start"
                    >
                      Pending Unlocks
                    </NavLink>
                  </li>
                  <li className="nav-item ">
                    <NavLink
                      to="/dealer/unlocked-cars"
                      className="nav-link px-4 d-flex align-items-center text-start"
                    >
                      Unlocked Cars
                    </NavLink>
                  </li>
                  <li className="nav-item ">
                    <NavLink
                      to="/mycredits"
                      className="nav-link px-4 d-flex align-items-center text-start"
                    >
                      My Credits
                    </NavLink>
                  </li>
                </ul>
              )}

              <div className="text-center mt-5 pt-3">
                <Link to="/logout" className=" btn btn-danger w-50 rounded-3">
                  <i className="fa-solid fa-file-export me-1"></i>Logout
                </Link>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
