import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import { Link } from "react-router-dom";
import Footer from "../Footer";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { Bounce, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";

const EditDealerProfile = () => {
  const optionToast = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
    transition: Bounce,
  };
  const [data, setData] = useState();
  const location = useLocation();
  const [userId, setUserId] = useState();
  const [file, setFile] = useState("");
  const baseUrl = "https://stagingsite.carloca.co.uk/apis/public/uploads/users/";

  const [editing, setEditing] = useState(false);

  const [editedData, setEditedData] = useState({
    email: data?.data?.email || "",
    first_name: data?.data?.first_name || "",
    last_name: data?.data?.last_name || "",
    mobile: data?.data?.mobile || "",
    phone: data?.data?.phone || "",
    addressFirst: data?.data?.address_first || "",
    addressSecond: data?.data?.address_second || "",
    city: data?.data?.city || "",
    country: data?.data?.country || "",
    postcode: data?.data?.postcode || "",
    address: data?.data?.address || "",
    cardNo: data?.data?.bank?.card_number || "",
    expiry: data?.data?.bank?.expiry || "",
    cvv: data?.data?.bank?.cvv || "",
  });

  const handleEditClick = () => {
    setEditing(true);
  };
  
  const getDataFromlocalStorage = (key) => {
    try {
      const serializedData = localStorage.getItem(key);
      return serializedData ? JSON.parse(serializedData) : null;
    } catch (error) {
      console.error("Error getting data from localStorage:", error);
      return null;
    }
  };


  const storedUserPreferences = getDataFromlocalStorage("userData");
  const handleInputChange = (field, value) => {
    
    setEditedData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
   
  };

 

  const handleProfile = (event) => {
   
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
    
    handleProfileData(selectedFile);
  };
  const saveDataTolocalStorage = (key, data) => {
    try {
      const serializedData = JSON.stringify(data);
      localStorage.setItem(key, serializedData);
    } catch (error) {
      console.error("Error saving data to localStorage:", error);
    }
  };
  const updateProfileDetails = async () => {
    try {
      const response = await axios.post(
        "https://stagingsite.carloca.co.uk/apis/public/api/user/update/profile",
        {
          user_id: storedUserPreferences?.id,
          email: editedData?.email,
          phone: editedData?.phone,
          address: editedData?.address,
          addressFirst: editedData?.addressFirst,
          addressSecond: editedData?.addressSecond,
          city: editedData?.city,
          country: editedData?.country,
          postcode: editedData?.postcode,
          first_name: editedData?.first_name,
          last_name: editedData?.last_name,
          mobile: editedData?.mobile,
        }
      );
    
      if (response?.data?.status) {
        // If the request was successful, update the user's profile details in the local storage
        toast.success(response?.data?.message, optionToast);
        saveDataTolocalStorage("userData", response?.data?.data);
        fetchData(userId);
      } else {
        toast.error(response?.data?.message, optionToast);
      }
    } catch (error) {
      console.log("An error occurred while fetching data.", error);
    }
  };
  
  const handleProfileData = (selectedFile) => {
    // console.log("dddd");

    const formData = {
      user_id: storedUserPreferences?.id,
      profile: selectedFile,
    };

    axios
      .post(
        "https://stagingsite.carloca.co.uk/apis/public/api/user/update/profile/picture",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        if (res?.data?.status) {
          toast.success(res?.data?.message, optionToast);
          setFile("");
          fetchData(userId);
        } else {
          toast.error(res?.data?.message, optionToast);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSaveClick = () => {
    setEditing(false);
    updateProfileDetails(editedData);
  };
  

  useEffect(() => {
  
    fetchData(storedUserPreferences?.id);
  },[]);
  

  const fetchData = async (userId) => {
    try {
      const response = await axios.post(
        "https://stagingsite.carloca.co.uk/apis/public/api/user/get/details",
        {
          user_id: storedUserPreferences?.id,
        }
      );
      setData(response?.data);
     
      if (response?.data?.status) {
        
        setEditedData({
          email: response?.data?.data?.email || "",
          first_name: response?.data?.data?.first_name || "",
          last_name: response?.data?.data?.last_name || "",
          phone: response?.data?.data?.phone || "",
          mobile: response?.data?.data?.mobile || "",
          addressFirst: response?.data?.data?.address_first || "",
          addressSecond: response?.data?.data?.address_second || "",
          city: response?.data?.data?.city || "",
          country: response?.data?.data?.country || "",
          postcode: response?.data?.data?.postcode || "",
          address: response?.data.data?.address || "",
          cardNo: response?.data?.data?.bank?.card_number || "",
          expiry: response?.data?.data?.bank?.expiry || "",
          cvv: response?.data?.data?.bank?.cvv || "", // Set the initial value for address
        });
      }
    } catch (error) {
      console.log("An error occurred while fetching data.", error);
    }
  };

  return (
    <>
      <Navbar />

      <div className="profile_update mt-5 py-5">
        <div className="col-lg-7 col-md-8 col-sm-12 px-3 mx-auto">
          <h2 className="mb-4 fw-bold">Profile Settings</h2>
          <div className="d-flex flex-column settings justify-content-center align-items-center flex-wrap">
            <div className="profileImg">
              <img
                className="update_img"
                alt=""
                src={
                  data?.data?.profile === undefined ||
                  data?.data?.profile === null
                    ? "img/user_default.png"
                    : `${baseUrl}${data?.data?.profile}`
                }
              />
              <label htmlFor="upload-img" className="editbtn">
                <i className="fa-solid fa-pen-to-square"></i>
              </label>
            </div>
            <h4 className="mt-3 fs-5 fw-bold text-primary">
              {data?.data?.first_name} {data?.data?.last_name}
            </h4>
            <div className="d-flex flex-column align-items-center mb-2">
              <input
                id="upload-img"
                onChange={handleProfile}
                type="file"
                style={{ display: "none", visibility: "hidden" }}
              />
            </div>
          </div>
          <div className="card mt-3 border-0">
            <div className="card-body pb-0">
              <div className="d-flex justify-content-between mb-3">
                <h6 className="fw-bold">Your Listing Contact Details</h6>
                <div className="border rounded px-2 py-1">
                  <button
                    className="d-flex align-items-center justify-content-center"
                    style={{
                      fontSize: "12px",
                      background: "none",
                      border: "none",
                      cursor: "pointer",
                    }}
                    onClick={!editing ? handleEditClick : handleSaveClick}
                  >
                    <i className="fa-solid fa-pen-to-square blue me-1"></i>
                    <span className="blue">{!editing ? "Edit" : "Update"}</span>
                  </button>
                </div>
              </div>
              <div className="row">
                <div className="col mt-2">
                  <span style={{fontSize:"14px",fontWeight:"600"}}>Email:</span>
                </div>
                <div className="col mt-2 text-end">
                  {editing ? (
                    <input
                      type="text"
                      value={editedData.email}
                      onChange={(e) =>
                        handleInputChange("email", e.target.value)
                      }
                      className="form-control"
                      style={{ fontSize: 14, paddingLeft: "10px" }}
                    />
                  ) : (
                    <span className="blue text-end" style={{fontSize:"14px"}}>
                      {editedData.email}
                    </span>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col mt-2">
                  <span style={{fontSize:"14px",fontWeight:"600"}}>First Name:</span>
                </div>
                <div className="col mt-2 text-end">
                  {editing ? (
                    <input
                      type="text"
                      value={editedData.first_name}
                      onChange={(e) =>
                        handleInputChange("first_name", e.target.value)
                      }
                      className="form-control"
                      style={{ fontSize: 14, paddingLeft: "10px" }}
                    />
                  ) : (
                    <span className="blue text-end" style={{fontSize:"14px"}}>
                      {editedData.first_name}
                    </span>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col mt-2">
                  <span style={{fontSize:"14px",fontWeight:"600"}}>Last Name:</span>
                </div>
                <div className="col mt-2 text-end">
                  {editing ? (
                    <input
                      type="text"
                      value={editedData.last_name}
                      onChange={(e) =>
                        handleInputChange("last_name", e.target.value)
                      }
                      className="form-control"
                      style={{ fontSize: 14, paddingLeft: "10px" }}
                    />
                  ) : (
                    <span className="blue text-end" style={{fontSize:"14px"}}>
                      {editedData.last_name}
                    </span>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col mt-2">
                  <span style={{fontSize:"14px",fontWeight:"600"}}>Phone:</span>
                </div>
                <div className="col mt-2 text-end">
                  {editing ? (
                    <input
                      type="text"
                      value={editedData.phone}
                      onChange={(e) =>
                        handleInputChange("phone", e.target.value)
                      }
                      className="form-control"
                      style={{ fontSize: 14, padding: "8px" }}
                    />
                  ) : (
                    <span className="blue text-end" style={{fontSize:"14px"}}>
                      {editedData.phone}
                    </span>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col mt-2">
                  <span style={{fontSize:"14px",fontWeight:"600"}}>Mobile:</span>
                </div>
                <div className="col mt-2 text-end">
                  {editing ? (
                    <input
                      type="text"
                      value={editedData.mobile}
                      onChange={(e) =>
                        handleInputChange("mobile", e.target.value)
                      }
                      className="form-control"
                      style={{ fontSize: 14, padding: "8px" }}
                    />
                  ) : (
                    <span className="blue text-end" style={{fontSize:"14px"}}>
                      {editedData.mobile}
                    </span>
                  )}
                </div>
              </div>
              <div className="row ">
                <div className="col mt-2">
                  <span style={{fontSize:"14px",fontWeight:"600"}}>Address Line 1:</span>
                </div>
                <div className="col mt-2 text-end">
                  {editing ? (
                    <input
                      type="text"
                      value={editedData.addressFirst}
                      onChange={(e) =>
                        handleInputChange("addressFirst", e.target.value)
                      }
                      className="form-control"
                      style={{ fontSize: 14, padding: "8px" }}
                    />
                  ) : (
                    <span className="blue text-end" style={{fontSize:"14px"}}>
                      {editedData.addressFirst}
                    </span>
                  )}
                </div>
              </div>
              <div className="row ">
                <div className="col mt-2">
                  <span style={{fontSize:"14px",fontWeight:"600"}}>Address Line 2:</span>
                </div>
                <div className="col mt-2 text-end">
                  {editing ? (
                    <input
                      type="text"
                      value={editedData.addressSecond}
                      onChange={(e) =>
                        handleInputChange("addressSecond", e.target.value)
                      }
                      className="form-control"
                      style={{ fontSize: 14, padding: "8px" }}
                    />
                  ) : (
                    <span className="blue text-end" style={{fontSize:"14px"}}>
                      {editedData.addressSecond}
                    </span>
                  )}
                </div>
              </div>
              <div className="row ">
                <div className="col mt-2">
                  <span style={{fontSize:"14px",fontWeight:"600"}}>City:</span>
                </div>
                <div className="col mt-2 text-end">
                  {editing ? (
                    <input
                      type="text"
                      value={editedData.city}
                      onChange={(e) =>
                        handleInputChange("city", e.target.value)
                      }
                      className="form-control"
                      style={{ fontSize: 14, padding: "8px" }}
                    />
                  ) : (
                    <span className="blue text-end" style={{fontSize:"14px"}}>
                      {editedData.city}
                    </span>
                  )}
                </div>
              </div>
              <div className="row ">
                <div className="col mt-2">
                  <span style={{fontSize:"14px",fontWeight:"600"}}>Country:</span>
                </div>
                <div className="col mt-2 text-end">
                  {editing ? (
                    <input
                      type="text"
                      value={editedData.country}
                      onChange={(e) =>
                        handleInputChange("country", e.target.value)
                      }
                      className="form-control"
                      style={{ fontSize: 14, padding: "8px" }}
                    />
                  ) : (
                    <span className="blue text-end" style={{fontSize:"14px"}}>
                      {editedData.country}
                    </span>
                  )}
                </div>
              </div>
              <div className="row pb-3">
                <div className="col mt-2">
                  <span style={{fontSize:"14px",fontWeight:"600"}}>Post Code:</span>
                </div>
                <div className="col mt-2 text-end">
                  {editing ? (
                    <input
                      type="text"
                      value={editedData.postcode}
                      onChange={(e) =>
                        handleInputChange("postcode", e.target.value)
                      }
                      className="form-control"
                      style={{ fontSize: 14, padding: "8px" }}
                    />
                  ) : (
                    <span className="blue text-end" style={{fontSize:"14px"}}>
                      {editedData.postcode}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>

        
          <div className="card mt-3 border-0">
            <div className="card-body pb-0">
              <div className="d-flex justify-content-between mb-3">
                <h6 className="fw-bold ">Account Details</h6>
              </div>
              <ul className="p-0">
                <li className="d-flex justify-content-between mt-2">
                  <span className="">Account Created:</span>
                  <span className=" blue">
                    {moment(data?.data?.created_at).format("YYYY-MM-DD")}
                  </span>
                </li>
                <li className="d-flex justify-content-between mt-2">
                  <span className="">User ID:</span>
                  <span className=" blue">Car-{data?.data?.id}</span>
                </li>
                <li className="d-flex justify-content-between mt-2">
                  <span className="">Status:</span>
                  <span className=" blue">Active</span>
                </li>
              </ul>
            </div>
          </div>

          <div className="d-flex justify-content-center mt-4">
            <Link to="/deleteAccount" className="btn btn-sm border-0 btn-primary">
              Delete account
            </Link>
          </div>
        </div>
      </div>
      <Footer />
      <ToastContainer />
    </>
  );
};

export default EditDealerProfile;
